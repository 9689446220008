.add-list {
  &__popup {
    width: 235px;
    height: 148px;
    background: #ffffff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 18px;
    box-sizing: border-box;
    border: 1px solid #dbdbdb;
    position: relative;
    top: -25px;
    left: 10px;

    .button {
      width: 100%;
      margin-top: 13px;
    }

    &-close-btn {
      position: absolute;
      right: -7px;
      top: -7px;
      cursor: pointer;
    }

    &-colors {
      display: flex;
      justify-content: space-between;
      margin-top: 13px;

      .badge {
        width: 16px;
        height: 16px;
        cursor: pointer;
        border: 2px solid transparent;

        &.active {
          border: 2px solid #525252;
        }
      }
    }
  }
}
