* {
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.todo__tasks::-webkit-scrollbar {  
  width: 10px;
  background: lightgrey;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.todo__tasks::-webkit-scrollbar-thumb:hover {
  background: rgb(131, 131, 131);
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.todo__tasks::-webkit-scrollbar-thumb:active {
  background: rgb(104, 104, 104);
}

body {
  background-color: #0078d4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.todo {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  font-size: 14px;

  &__sidebar {
    background-color: #f4f6f8;
    width: 200px;
    height: calc(100% - 120px);
    border-radius: 10px 0 0 10px;
    border-right: 1px solid #f1f1f1;
    padding: 60px 20px;
  }

  &__tasks {
    flex: 1;
    padding: 60px;
    overflow: auto;
  }
}

.field {
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 12px;
  width: 100%;
  font-size: 14px;

  &:focus {
    border-color: #dbdbdb;
  }
}

.button {
  background: #4dd599;
  border-radius: 4px;
  color: #fff;
  border: 0;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: darken(#4dd599, 6%);
  }

  &:active {
    background: darken(#4dd599, 10%);
  }

  &:disabled {
    background: #d8d8d8;
  }

  &--grey {
    background: #f4f6f8;
    color: #444444;

    &:hover {
      background: darken(#f4f6f8, 3%);
    }

    &:active {
      background: darken(#f4f6f8, 6%);
    }
  }
}
