.list {
  margin-bottom: 30px;

  &__add-btn {
    span {
      opacity: 0.5;
    }
    &:hover {
      path {
        stroke: #000000;
      }
      span {
        opacity: 1;
      }
    }
  }

  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 12px;

    span {
      flex: 1;
    }

    &.active {
      background: #ffffff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
    }

    i {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
  }
}
