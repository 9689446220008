.badge {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 30px;

  &--grey {
    background-color: #c9d1d3;
  }

  &--lime {
    background-color: #b6e6bd;
  }

  &--purple {
    background-color: #c355f5;
  }

  &--black {
    background-color: #08001a;
  }

  &--red {
    background-color: #ff6464;
  }

  &--green {
    background-color: #42b883;
  }

  &--blue {
    background-color: #64c4ed;
  }

  &--pink {
    background-color: #ffbbcc;
  }
}